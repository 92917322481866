class FastTrackLoader {
  constructor() {
    let isNewIntegration = false;

    if (window && window.fasttrack && window.fasttrack.integrationVersion === 2.0) {
      isNewIntegration = true;
    }

    if (!isNewIntegration) {
      const cssId = 'fasttrack-crm-css'; // you could encode the css path itself to generate id..
      if (!document.getElementById(cssId)) {
        const head = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        link.id = cssId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `${process.env.FILE_LOCATION}/css/fasttrack-crm-app.css?v=${process.env.GIT_COMMITHASH
        }`;
        link.media = 'all';
        head.appendChild(link);

        window.fasttrackVersion = (process && process.env && process.env.GIT_COMMITHASH) ? process.env.GIT_COMMITHASH : 'local';
      }


      const appDiv = document.createElement('div');
      appDiv.id = 'fasttrack-crm';

      document.body.appendChild(appDiv);
    }


    if (window && window.fasttrack && window.fasttrack.enableRewards) {
      const style = document.createElement('style');
      style.innerText = `@property --xp-progress {
        syntax: '<angle>';
        inherits: false;
        initial-value: 0deg;
      }`;
      document.head.appendChild(style);

      const scratchStyle = document.createElement('style');
      scratchStyle.innerText = `.hidden-scratch {
        min-height: 100vh;
        height: 100%;
        overflow: auto;
        overscroll-behavior-y: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        }`;
      document.head.appendChild(scratchStyle);


      const rewardsWrapper = document.createElement('div');
      rewardsWrapper.className = 'ft-rewards-wrapper';
      document.body.appendChild(rewardsWrapper);

      const crmScript = document.createElement('script');
      crmScript.src = `https://crm-lib-staging.fasttrack-solutions.com/wc/rewards-main.umd.cjs?v=${new Date().getTime()}`;
      crmScript.async = true;
      crmScript.onload = function () {
        console.log('Fast Track Rewards loaded');
        if (isNewIntegration) {
          window.initFtRewards();
        }
      };
      document.body.appendChild(crmScript);
    }

    if (!isNewIntegration) {
      const scriptVendor = document.createElement('script');
      scriptVendor.async = true;
      scriptVendor.src = `${process.env.FILE_LOCATION
      }/js/fasttrack-crm-chunk-vendors.js?v=${process.env.GIT_COMMITHASH}`;
      scriptVendor.onload = function () { /* eslint-disable-line */
        console.log('Fasttrack CRM: Vendor JS loaded');
        const scriptMain = document.createElement('script');
        scriptMain.async = true;
        scriptMain.onload = function () { /* eslint-disable-line */
          console.log('Fasttrack CRM: Main script loaded');
        };
        scriptMain.src = `${process.env.FILE_LOCATION
        }/js/fasttrack-crm-app.js?v=${process.env.GIT_COMMITHASH}`;

        document.body.appendChild(scriptMain);
      };

      document.body.appendChild(scriptVendor);
    }
  }
}

window.FastTrackLoader = FastTrackLoader;
